<template>
  <v-card rounded>
    <v-card-text class="text-center">
      <v-avatar rounded size="100">
        <v-icon size="65">far fa-question-circle</v-icon>
      </v-avatar>
      <p>Er zijn nog geen vragen in deze categorie</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'FaqEmpty',
};
</script>
