<template>
  <div>
    <FaqEmpty v-if="isEmpty"/>
    <v-card rounded v-else>
      <v-card-text>
        <template v-if="!loading">
          <FaqDisplay v-for="(faq, index) in faqs"
                      :faq="faq"
                      :key="index"
                      :has-bottom-line="index < (faqs.length - 1)"/>
        </template>

        <v-row v-else justify="center" no-gutters class="pa-12">
          <VProgressCircular size="120" color="primary" indeterminate/>
        </v-row>
      </v-card-text>
    </v-card>

    <v-snackbar :timeout="3000" color="error" v-model="snackbar">
      Er is iets misgegaan met het ophalen van alle faq
    </v-snackbar>
  </div>
</template>

<script>
import { fetchAllFaqGroupQuestions } from '@/api/faq';
import FaqDisplay from '@/components/faq/FaqDisplay';
import FaqEmpty from '@/views/faq/Empty.vue';

export default {
  name: 'FaqDetails',
  components: {
    FaqEmpty,
    FaqDisplay,
  },
  data: () => ({
    selectedFaqGroupId: null,
    faqs: [],
    loading: false,
    isEmpty: false,
    snackbar: false,
  }),
  created() {
    this.snackbar = false;
  },
  watch: {
    '$route.params.id': {
      handler() {
        this.selectedFaqGroupId = this.$route.params.id;
        this.getGroupQuestions();
      },
      immediate: true,
    },
  },
  methods: {
    async getGroupQuestions() {
      this.loading = true;
      this.isEmpty = false;

      try {
        const response = await fetchAllFaqGroupQuestions(this.selectedFaqGroupId);
        this.faqs = response.data;
      } catch {
        this.snackbar = true;
      }

      this.isEmpty = !this.faqs.length;
      this.loading = false;
    },
  },
};
</script>
